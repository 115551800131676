import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";

import { BrowserRouter as Router } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import * as firebase from "firebase/app";
import "firebase/auth";

import ReactGA from "react-ga";
ReactGA.initialize("UA-163983361-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const firebaseConfig = {
  apiKey: "AIzaSyAcSp3AhJXRAI5HmXJmI1vh4Aj0yzxy4fM",
  authDomain: "alpha-tradelog.firebaseapp.com",
  databaseURL: "https://alpha-tradelog.firebaseio.com",
  projectId: "alpha-tradelog",
  storageBucket: "alpha-tradelog.appspot.com",
  messagingSenderId: "513031909832",
  appId: "1:513031909832:web:878e548ad132f17ec690fd",
  measurementId: "G-5G30C373N2",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
