import React, { useEffect, useState } from "react";
import { getEntries } from "../api";
import { OptionRow, EquityRow } from "../components";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const renderRow = (item) => {
  if (item.instrument === "option") {
    return <OptionRow data={item} />;
  }
  return <EquityRow data={item} />;
};

const TextSkeleton = () => {
  return (
    <div>
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </div>
  );
};

const EntriesGroupedByUser = ({ entryMap }) => {
  return Object.keys(entryMap).map((key) => {
    const userEntries = entryMap[key];

    return (
      <ExpansionPanel key={key}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          {key}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List dense>
            {userEntries.map((userEntry) => {
              return (
                <ListItem key={userEntry._id}>{renderRow(userEntry)}</ListItem>
              );
            })}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });
};

const Today = () => {
  const [entryMap, setEntryMap] = useState();

  async function fetch() {
    const res = await getEntries();
    setEntryMap(res);
  }

  useEffect(() => {
    fetch();
  }, []);

  const entriesLoaded = Boolean(entryMap);
  const numEntries = entriesLoaded ? Object.keys(entryMap).length : null;

  return (
    <div>
      {!entriesLoaded && <TextSkeleton />}
      {entriesLoaded && numEntries === 0 && (
        <Typography>No one has become outraged yet.</Typography>
      )}
      {entriesLoaded && numEntries > 0 && (
        <EntriesGroupedByUser entryMap={entryMap} />
      )}
    </div>
  );
};

export { Today };
