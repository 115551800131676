import axios from "axios";
import _ from "lodash";
import * as firebase from "firebase/app";

const getHostname = () =>
  process.env.REACT_APP_SERVER_HOSTNAME
    ? process.env.REACT_APP_SERVER_HOSTNAME
    : "";

const authenticatedGet = async (url, config) => {
  const token = await firebase.auth().currentUser.getIdToken(true);

  const configWithAuth = { ...config };
  configWithAuth.headers = { Authorization: `${token}` };

  return axios.get(getHostname() + url, configWithAuth);
};

const authenticatedPost = async (url, data, config) => {
  const token = await firebase.auth().currentUser.getIdToken(true);

  const configWithAuth = { ...config };
  configWithAuth.headers = { Authorization: `${token}` };

  return axios.post(getHostname() + url, data, configWithAuth);
};

const getEntries = async () => {
  const res = await authenticatedGet("/entry/today", {
    params: {
      today: new Date(),
      tzName: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  return _.groupBy(res.data, "user");
};

const getViewPreview = async (startDate, endDate, underlying, instrument) => {
  const res = await authenticatedPost("/view/preview", {
    params: {
      startDate,
      endDate,
      underlying,
      instrument,
    },
  });

  return _.groupBy(res.data, (data) => {
    const createdAt = data.created_at;
    return createdAt.substring(0, createdAt.indexOf("T"));
  });
};

const getArchivedEntries = async (username, startDate, endDate) => {

};

export { getEntries, getArchivedEntries, getViewPreview };
