import React from "react";
import { getFormattedDate, getFormattedTime } from "../util/format";
import Typography from "@material-ui/core/Typography";

const parseStrikes = (strikes) => {
  return strikes.join("/");
};

const parseOptionType = (data) => {
  if (
    data.strategy &&
    data.strategy !== "naked" &&
    data.strategy !== "vertical"
  ) {
    return data.strategy;
  }

  if (data.option_type === "p") {
    return "PUT";
  } else if (data.option_type === "c") {
    return "CALL";
  } else {
    return "COMPLEX";
  }
};

const OptionRow = ({ data }) => {
  return (
    <Typography variant="subtitle1" gutterBottom>
      {getFormattedTime(data.created_at)} <b>{data.action}</b> {data.quantity}{" "}
      {data.underlying.toUpperCase()} {getFormattedDate(data.expiration)}{" "}
      {parseStrikes(data.strike)} {parseOptionType(data)} @ <b>{data.cost}</b>
    </Typography>
  );
};

export { OptionRow };
