import React from "react";
import Typography from "@material-ui/core/Typography";

const Views = () => {
  return (
    <div>
      <div>
        <Typography variant="h6" gutterBottom>
          My Views
        </Typography>
      </div>
    </div>
  );
};

export { Views };
