import React, { useState, useEffect } from "react";
import { Archive, Today, Views } from "./";
import { Switch, Route, useLocation, Link, useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../logo-alpha.png";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import { signOut, useAuth } from "../authentication/firebase";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { CreateView } from "./CreateView";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: 200,
  },
  breadcrumbContainer: {
    marginBottom: 12,
  },
  appBar: {
    marginBottom: 10,
  },
}));

const breadcrumbMap = {
  "/views": "Views",
  "/views/create": "Create View",
};

const AtlBreadcrumbs = ({ location }) => {
  const pathnames = location.pathname.split("/").filter((x) => x);

  if (pathnames.length < 2) {
    return null;
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 24 }}>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const mapping = breadcrumbMap[to];

        return last ? (
          <Typography color="textPrimary" key={to}>
            {mapping ? mapping : value}
          </Typography>
        ) : (
          <Link color="inherit" to={to} key={to}>
            {mapping ? mapping : value}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

const AuthenticatedApp = () => {
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState();

  const [avatarAnchor, setAvatarAnchor] = React.useState(null);
  const handleClose = () => {
    setAvatarAnchor(null);
  };

  useEffect(() => {
    const tab = location.pathname.split("/");
    // this assumes tabs are the first level in the path always
    const tabFormatted = `/${tab[1]}`;

    setCurrentTab(tabFormatted);
  }, [location]);

  // console.log(tabFormatted);
  // default to / on unknown pathname

  // console.log(currentTab);
  const { user } = useAuth();

  //TODO have this menu code in authenticated app only
  const menu = (
    <Menu
      anchorEl={avatarAnchor}
      open={Boolean(avatarAnchor)}
      onClose={handleClose}
    >
      <MenuItem key="profile" onClick={handleClose}>
        <Link to="/profile">Profile</Link>
      </MenuItem>
      <MenuItem key="connections" onClick={handleClose}>
        <Link to="/connections">Connections</Link>
      </MenuItem>
      <MenuItem key="support" onClick={handleClose}>
        <Link to="/support">Support</Link>
      </MenuItem>
      <Divider />
      <MenuItem key="logout" onClick={() => signOut()}>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Container maxWidth="sm">
          <Toolbar>
            <div className={classes.title}>
              <img
                src={logo}
                alt="logo"
                className={classes.logo}
                style={{ width: 200 }}
              />
            </div>
            <Avatar
              style={{
                backgroundColor: "#00aa55",
                cursor: "pointer",
              }}
              size="large"
              onClick={(e) => {
                setAvatarAnchor(e.currentTarget);
              }}
            >
              {user.displayName.charAt(0)}
            </Avatar>
          </Toolbar>
          {menu}
          <Tabs
            value={currentTab}
            onChange={(e, value) => {
              setCurrentTab(value);
              history.push(value);
            }}
          >
            <Tab value="/" label="Today" />
            <Tab value="/views" label="Views" />
            <Tab value="/archive" label="Archive" />
          </Tabs>
        </Container>
      </AppBar>
      <Container maxWidth="sm">
        <AtlBreadcrumbs
          location={location}
          className={classes.breadcrumbContainer}
        />
        <Switch>
          <Route path="/views/create">
            <CreateView />
          </Route>
          <Route path="/views">
            <Views />
          </Route>
          <Route path="/archive">
            <Archive />
          </Route>
          <Route path="/">
            <Today />
          </Route>
        </Switch>
      </Container>
    </div>
  );
};

export { AuthenticatedApp };
