import React from "react";

import logo from "./logo-alpha.png"; // Tell Webpack this JS file uses this image

import { AuthenticatedApp } from "./containers/AuthenticatedApp";

import { Switch, Route } from "react-router-dom";
import { googleSignIn, useAuth } from "./authentication/firebase";
import { makeStyles } from "@material-ui/core/styles";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: 200,
  },
}));

const App = () => {
  const { user, authLoading } = useAuth();

  const classes = useStyles();

  const UnauthenticatedApp = () => {
    return (
      <AppBar position="static" style={{ marginBottom: 10 }}>
        <Container maxWidth="sm">
          <Toolbar>
            <div className={classes.title}>
              <img src={logo} alt="logo" className={classes.logo} />
            </div>
            <Button
              color="secondary"
              onClick={async () => {
                await googleSignIn();
              }}
            >
              Login
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
    );
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={authLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Switch>
        <Route path="/">
          {!user && <UnauthenticatedApp />}
          {user && <AuthenticatedApp />}
        </Route>
      </Switch>
    </div>
  );
};

export default App;
