import React, { useState } from "react";
import { getArchivedEntries } from "../api";

import { useAuth } from "../authentication/firebase";
import Button from "@material-ui/core/Button";
import { DatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";

import { EntriesGroupedByDay } from "../components/EntriesGroupedByDay";

const Archive = () => {
  const { user } = useAuth();

  const [username, setUsername] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate] = useState();
  const [dayMap, setDayMap] = useState({});
  const [searchLoading] = useState(false);

  const submitSearch = async () => {
    const res = await getArchivedEntries(username, startDate, endDate);
    setDayMap(res);
  };

  const hasEntries = dayMap && Object.keys(dayMap).length > 0;

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        <DatePicker value={startDate} onChange={setStartDate} />
      </div>
      <div style={{ marginBottom: 4 }}>
        <TextField
          placeholder="username"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
        />
      </div>

      <div>
        <Button
          size="small"
          style={{ marginBottom: 8 }}
          onClick={() => setUsername(user.email)}
        >
          Me
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: 12,
        }}
      >
        <Button type="primary" onClick={submitSearch} loading={searchLoading}>
          Search
        </Button>
      </div>
      {hasEntries && <EntriesGroupedByDay dayMap={dayMap} />}
    </div>
  );
};

export { Archive };
