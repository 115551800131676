import React from "react";
import { getFormattedTime } from "../util/format";
import Typography from "@material-ui/core/Typography";

const EquityRow = ({ data }) => {
  return (
    <Typography variant="subtitle1" gutterBottom>
      {getFormattedTime(data.created_at)} <b>{data.action}</b> {data.quantity}{" "}
      {data.underlying.toUpperCase()} @ <b>{data.cost}</b>
    </Typography>
  );
};

export { EquityRow };
