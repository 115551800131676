import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { OptionRow, EquityRow } from "../components";

const renderRow = (item) => {
  if (item.instrument === "option") {
    return <OptionRow data={item} />;
  }
  return <EquityRow data={item} />;
};

export const EntriesGroupedByDay = ({ dayMap }) => {
  return Object.keys(dayMap).map((key) => {
    const dayEntries = dayMap[key];

    return (
      <ExpansionPanel key={key}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          {key}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List dense>
            {dayEntries.map((userEntry) => {
              return (
                <ListItem key={userEntry._id}>{renderRow(userEntry)}</ListItem>
              );
            })}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });
};
