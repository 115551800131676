import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getViewPreview } from "../api";
import { EntriesGroupedByDay } from "../components/EntriesGroupedByDay";

const useStyles = makeStyles((theme) => ({
  fieldSpacing: {
    marginRight: 12,
  },
  formContainer: {
    marginBottom: 12,
  },
  instrumentSelect: {
    minWidth: 220,
  },
  buttonSpacing: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 24,
  },
  form: {
    width: "100%",
  },
}));

const CreateView = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [underlying, setUnderlying] = useState("");
  const [instrument, setInstrument] = useState("");
  const [name, setName] = useState("");

  const [dayMap, setDayMap] = useState({});

  const hasEntries = Object.keys(dayMap).length > 0;

  return (
    <div>
      <div>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">View Parameters</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <form noValidate autoComplete="off" className={classes.form}>
              <div className={classes.formContainer}>
                <TextField
                  id="standard-basic"
                  label="View Name"
                  className={classes.fieldSpacing}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                <div>
                  <DatePicker
                    value={startDate}
                    onChange={setStartDate}
                    className={classes.fieldSpacing}
                    label="Start Date"
                  />
                  <DatePicker
                    value={endDate}
                    className={classes.fieldSpacing}
                    onChange={setEndDate}
                    label="End Date"
                  />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="Underlying"
                    className={classes.fieldSpacing}
                    value={underlying}
                    onChange={(event) => setUnderlying(event.target.value)}
                  />
                  <FormControl className={classes.instrumentSelect}>
                    <InputLabel id="demo-simple-select-label">
                      Instrument
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={instrument}
                      onChange={(event) => setInstrument(event.target.value)}
                    >
                      <MenuItem value={"option"}>Option</MenuItem>
                      <MenuItem value={"equity"}>Equity</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.buttonSpacing}>
                <Button
                  onClick={async () => {
                    const res = await getViewPreview(
                      startDate,
                      endDate,
                      underlying,
                      instrument
                    );

                    setDayMap(res);
                  }}
                >
                  Preview
                </Button>
                <Button>Create View</Button>
              </div>
            </form>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>

      {hasEntries && <EntriesGroupedByDay dayMap={dayMap} />}
    </div>
  );
};

export { CreateView };
