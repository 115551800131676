import React from "react";
import * as firebase from "firebase/app";

export const useAuth = () => {
  const [user, setUser] = React.useState(firebase.auth().currentUser);
  const [authLoading, setAuthLoading] = React.useState(false);

  function onChange(user) {
    setUser(user);
    setAuthLoading(false);
  }

  React.useEffect(() => {
    // listen for auth state changes
    setAuthLoading(true);
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);

  return { user, authLoading };
};

export const googleSignIn = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  await firebase.auth().signInWithPopup(provider);
};

export const signOut = async () => {
  await firebase.auth().signOut();
};
