import { DateTime } from "luxon";

const getFormattedDate = date => {
  return DateTime.fromISO(date)
    .setZone("utc")
    .toFormat("M/d");
};

const getFormattedTime = date => {
  return DateTime.fromISO(date).toFormat("tt");
};

export { getFormattedDate, getFormattedTime };
